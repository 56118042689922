<template>
  <v-container v-if="users">
    <v-data-table
      :headers="headers"
      :items="users"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="userEmail"
      show-expand
      class="elevation-1"
      :search="search"
      calculate-widths
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.userMeta="{ item }">
        <span v-if="item.userMeta.userCreated">
          {{
            new Date(item.userMeta.userCreated) instanceof Date &&
            !isNaN(new Date(item.userMeta.userCreated))
              ? new Date(item.userMeta.userCreated).toLocaleDateString()
              : '---'
          }}
        </span>
      </template>
      <template v-slot:item.userPaid="{ item }">
        <span class="text-capitalize">{{ item.userPaid }}</span>
      </template>
      <template v-slot:item.userMarket="{ item }">
        <span class="text-capitalize">{{ item.userMarket }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-sheet> </v-sheet>
          <v-sheet>
            <v-expansion-panels accordion flat tile>
              <v-expansion-panel v-for="(key, i) in Object.keys(item)" :key="i">
                <v-expansion-panel-header>
                  {{ key }} :
                  <span v-if="JSON.stringify(item[key]).length < 30">
                    : {{ item[key] }}</span
                  >
                  <span v-else> : Expand for more info ...</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="typeof item[key] === 'object'">
                    <v-expansion-panels accordion flat tile>
                      <v-expansion-panel
                        v-for="(key2, j) in Object.keys(item[key])"
                        :key="j"
                      >
                        <v-expansion-panel-header>
                          {{ key2 }} :
                          <span
                            v-if="JSON.stringify(item[key][key2]).length < 30"
                          >
                            : {{ item[key][key2] }}</span
                          >
                          <span v-else> : Expand for more info ...</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- <span v-if="(item[key][key2] instanceof Object)">

                              </span> -->
                          <code>
                            <pre>{{ item[key][key2] }}</pre>
                          </code>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </span>
                  <span v-else>
                    <code>
                      <pre>{{ item[key] }}</pre>
                    </code>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-sheet>
          <v-sheet height="300">
            <gmaps-map :options="mapOptions">
              <gmaps-marker
                v-for="(marker, i) in userMarkers"
                :key="i"
                :position="marker.lastLoginLocation"
                icon="http://maps.google.com/mapfiles/ms/micons/dollar.png"
                :title="
                  'Username: ' +
                  item.userName +
                  '\n' +
                  'Paid: ' +
                  marker.userPaid +
                  '\n' +
                  'Created: ' +
                  new Date(marker.userCreated).toLocaleString() +
                  '\n' +
                  'Last login: ' +
                  new Date(marker.lastLogin).toLocaleString() +
                  '\n' +
                  'Last login location: ' +
                  marker.lastLoginLocation.lat.toString() +
                  ',' +
                  marker.lastLoginLocation.lng.toString() +
                  '\n' +
                  'Total locations: ' +
                  marker.totalLoginLocations
                "
              />
            </gmaps-map>
          </v-sheet>
        </td>
      </template>
    </v-data-table>
    <v-layout row wrap>
      <v-flex xs4 v-for="(item, idx) in feedbacks" :key="idx">
        <v-card class="ma-3">
          <v-card-title>
            {{ new Date(item.created).getUTCFullYear() }}-{{
              new Date(item.created).getUTCMonth()
            }}-{{ new Date(item.created).getUTCDate() }}
            @
            {{ new Date(item.created).getUTCHours() }}:{{
              new Date(item.created).getUTCMinutes()
            }}
          </v-card-title>
          <v-card-text>
            <br />
            {{ item.from }}
            <br />
            <hr />
            {{ item.to }}
            <br />
            <hr />
            {{ item.feedback }}
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps';

export default {
  name: 'Users',
  components: {
    gmapsMap,
    gmapsMarker,
  },
  data() {
    // NYSE 40.706881,-74.0134541
    return {
      search: '',
      expanded: [],
      singleExpand: true,
      headers: [
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'userEmail',
        },
        { text: 'Since', width: '80', value: 'userMeta' },
        { text: 'Paid', width: '80', value: 'userPaid' },
        { text: 'Role', width: '80', value: 'userRole' },
        { text: 'Package', width: '100', value: 'userPackage' },
        { text: 'Market', width: '100', value: 'userMarket' },
      ],
      userMarkers: null,
      mapOptions: {
        center: { lat: 40.7068, lng: -74.0134 },
        zoom: 8,
        backgroundColor: '#EEEEEE',
      },
    };
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    feedbacks() {
      return this.$store.getters.getFeedbacks;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.$store.getters.getUserRole.includes('Admin');
    },
  },
  methods: {
    fetchUsers() {
      this.$store.dispatch('getUsersRecords');
    },
    fetchFeedbacks() {
      this.$store.dispatch('getFeedbackRecords');
    },
    showUserOnMap(email) {
      let userObjectArray = this.users.filter((object) => {
        return object.userEmail === email;
      });
      // Filter for existing locations (user can have multiple locations)
      this.userMarkers = userObjectArray.map((object) => {
        const userLocationsIPs = Object.keys(object.userMeta.userGeoLocation);
        const userLastLocation =
          object.userMeta.userGeoLocation[
            userLocationsIPs[userLocationsIPs.length - 1]
          ];
        return {
          userName: object.userName,
          userPaid: object.userPaid,
          userCreated: object.userMeta.userCreated,
          lastLogin: object.userMeta.userLastLogin,
          lastLoginLocation: {
            lat: parseFloat(userLastLocation.loc.split(',')[0]),
            lng: parseFloat(userLastLocation.loc.split(',')[1]),
          },
          totalLoginLocations: userLocationsIPs.length,
        };
      });
      // Set map to latest user location
      this.mapOptions.center = this.userMarkers[
        this.userMarkers.length - 1
      ].lastLoginLocation;
      this.mapOptions.zoom = 12;
    },
  },
  mounted() {
    if (!this.users) {
      this.fetchUsers();
    }
    if (!this.feedbacks) {
      this.fetchFeedbacks();
    }
  },
  created() {},
  watch: {
    users() {},
    expanded() {
      if (this.expanded.length > 0) {
        if (
          this.expanded[0].userMeta &&
          this.expanded[0].userMeta.userGeoLocation
        ) {
          const lastExpendedUserEmail = this.expanded[this.expanded.length - 1]
            .userEmail;
          this.showUserOnMap(lastExpendedUserEmail);
        } else {
          this.userMarkers = null;
          this.mapOptions = {
            center: { lat: 40.7068, lng: -74.0134 },
            zoom: 8,
            backgroundColor: '#EEEEEE',
          };
        }
      }
    },
  },
};
</script>

<style scoped></style>
