<template>
  <v-container>
    <v-card
      target="_blank"
      href="https://analytics.google.com/analytics/web/?hl=en_US#/p203074404/reports/home"
    >
      <v-card-title class="justify-center">Google</v-card-title>
    </v-card>
    <v-card
      target="_blank"
      href="https://dashboard.stripe.com/dashboard"
      class="mt-2 text-center"
    >
      <v-card-title class="justify-center">Stripe</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Stat',
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
};
</script>

<style scoped></style>
