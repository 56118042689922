<template>
  <v-container fluid>
    <v-row justify-center>
      <v-col>
        <info></info>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Info from '@/components/account/Info';

export default {
  name: 'Account',
  components: {
    Info,
  },
};
</script>

<style scoped></style>
