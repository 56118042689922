<template>
  <v-container>
    App: <br />
    Version: {{ siteVersion.split('-')[0] }} <br />
    Deployment time: {{ siteVersion.split('-')[1] }} <br />
    <hr />
    Environment: <br />
    Vue(Node): {{ siteNodeEnvironment }} <br />
    Firebase: {{ siteFirebaseEnvironment }} <br />
    <hr />
    Code: <br />
    Branch: {{ siteCode.split('@')[2] }} <br />
    Last commit time: {{ siteCode.split('@')[0] }},
    {{ siteVersion.split('@')[1] }} <br />
    Last commit name: {{ siteCode.split('@')[3] }} <br />
    <hr />
    <dir v-if="siteSettings">
      Settings:
      <v-btn
        icon
        color="green"
        :disabled="!siteSettingsChanged"
        @click.stop="setSiteSettings"
      >
        <v-icon>mdi-cached</v-icon>
      </v-btn>
      <v-switch
        v-model="siteSignIn"
        class="my-1"
        label="Sign In"
        @change="siteSettingsClicked"
      ></v-switch>
      <v-switch
        v-model="siteSignUp"
        class="my-1"
        label="Sign Up"
        @change="siteSettingsClicked"
      ></v-switch>
      <v-switch
        v-model="siteFeedback"
        class="my-1"
        label="Feedback"
        @change="siteSettingsClicked"
      ></v-switch>
      <hr />
    </dir>
  </v-container>
</template>

<script>
export default {
  name: 'Site',
  data() {
    return {
      siteVersion: process.env.VUE_APP_VERSION,
      siteCode: process.env.VUE_APP_LAST_COMMIT,
      siteNodeEnvironment: process.env.NODE_ENV,
      siteSignIn: null,
      siteSignUp: null,
      siteFeedback: null,
      siteSettingsChanged: false,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.getters.getSiteSettings;
    },
    siteFirebaseEnvironment() {
      return this.$store.getters.getEnvironment;
    },
  },
  methods: {
    getSiteSettings() {
      this.$store.dispatch('getSiteSettingsRecords');
    },
    setSiteSettings() {
      this.$store.dispatch('setSiteSettingsRecords', {
        signIn: this.siteSignIn,
        signUp: this.siteSignUp,
        feedback: this.siteFeedback,
      });
      this.siteSettingsChanged = false;
    },
    siteSettingsClicked() {
      this.siteSettingsChanged =
        this.siteSignIn !== this.siteSettings.signIn ||
        this.siteSignUp !== this.siteSettings.signUp ||
        this.siteFeedback !== this.siteSettings.feedback;
    },
  },
  watch: {
    siteSettings() {
      this.siteSignIn = this.siteSettings.signIn;
      this.siteSignUp = this.siteSettings.signUp;
      this.siteFeedback = this.siteSettings.feedback;
    },
  },
  created() {},
  mounted() {
    this.getSiteSettings();
  },
};
</script>

<style scoped></style>
