<template>
  <v-container>
    <v-sheet v-if="statisticNews" min-height="400" class="mx-5">
      <v-tabs
        v-model="tab"
        background-color="gray accent-1"
        centered
        icons-and-text
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        height="40"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="(key, i) in markets" :key="i">
          {{ key }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(key, i) in markets" :key="i">
          <v-row no-gutters align="center" justify="center" height="300">
            <v-col col="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="105">Date</th>
                      <th class="text-left text-capitalize">
                        {{ key }} News Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="tickerNewsRecord in statisticNews[key]"
                      :key="tickerNewsRecord.id"
                    >
                      <td>{{ tickerNewsRecord.id }}</td>
                      <td>
                        <v-chip
                          v-for="tickerNews in tickerNewsRecord.tickersNews"
                          :key="Object.keys(tickerNews)[0]"
                          small
                          color="info"
                          class="ma-1"
                          :href="
                            `https://www.google.com/search?tbm=fin&q=` +
                            Object.keys(tickerNews)[0]
                          "
                          target="_blank"
                          >{{ Object.keys(tickerNews)[0] }} :
                          {{ Object.values(tickerNews)[0] }}
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
    <v-sheet v-if="statisticPrices" class="mx-5">
      <hr />
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="105">Date</th>
              <th class="text-left">
                Prices Amount
                <v-chip small color="info" class="ma-1">Actual/Forecast</v-chip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="date in Object.keys(statisticPrices)" :key="date">
              <td>
                {{ date }}
              </td>
              <td>
                <v-chip
                  v-for="priceRecord in getActualPredictedPrices(
                    statisticPrices[date]
                  )"
                  :key="priceRecord.ticker"
                  small
                  color="info"
                  class="ma-1"
                  >{{ priceRecord.ticker }} : {{ priceRecord.actual }}/{{
                    priceRecord.predicted
                  }}
                </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'News',
  data() {
    return {
      tab: null,
      weekAgoDate: null,
      markets: null,
    };
  },
  computed: {
    statisticNews() {
      return this.$store.getters.getStatisticNews;
    },
    statisticPrices() {
      return this.$store.getters.getStatisticPrices;
    },
  },
  methods: {
    getStatisticNews() {
      this.weekAgoDate = new Date();
      this.weekAgoDate.setDate(this.weekAgoDate.getDate() - 5);
      this.$store.dispatch('getStatisticNewsRecords', this.weekAgoDate);
    },
    async getStatisticPrices() {
      let tickersByDate = {};
      const markets = Object.keys(this.statisticNews).filter((market) => {
        return !market.includes('success');
      });
      this.markets = markets;
      for (let market of markets) {
        let marketNewsRecords = this.statisticNews[market];
        for (let marketNewsRecord of marketNewsRecords) {
          let tickersForDay = marketNewsRecord.tickersNews.map((ticker) => {
            return (
              Object.keys(ticker)[0] + '-' + market.slice(0, 2).toUpperCase()
            );
          });
          tickersByDate[marketNewsRecord.id] = tickersByDate[
            marketNewsRecord.id
          ]
            ? [...tickersByDate[marketNewsRecord.id], ...tickersForDay]
            : tickersForDay;
        }
      }
      this.$store.dispatch('getStatisticPricesRecords', tickersByDate);
    },
    getActualPredictedPrices(dateObject) {
      let dateTickersPrices = [];
      const dateTickers = Object.keys(dateObject);
      for (const dateTicker of dateTickers) {
        if (dateTicker.includes('success')) continue;
        let priceRecords = dateObject[dateTicker];
        let dateTickerObject = {};
        dateTickerObject.ticker = dateTicker;
        dateTickerObject.actual = 0;
        dateTickerObject.predicted = 0;
        for (const priceRecord of priceRecords) {
          if (priceRecord.actual) dateTickerObject.actual += 1;
          if (priceRecord.predicted) dateTickerObject.predicted += 1;
        }
        dateTickersPrices.push(dateTickerObject);
      }
      return dateTickersPrices;
    },
  },
  watch: {
    statisticNews() {
      this.getStatisticPrices();
    },
    statisticPrices() {},
  },
  created() {},
  mounted() {
    if (!this.statisticNews) this.getStatisticNews();
  },
};
</script>

<style scoped></style>
