<template>
  <v-container>
    <div v-if="!accessRequestShown">
      <v-tabs
        v-model="tab"
        background-color="gray accent-1"
        centered
        icons-and-text
        height="40"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#Site"> Site </v-tab>
        <v-tab href="#Users">
          <span v-if="users" class="mt-1">
            <v-badge top offset-y="7" color="green" :content="users.length"
              >Users</v-badge
            >
          </span>
          <span v-else class="mt-2">Users</span>
        </v-tab>
        <v-tab href="#Map"> Map </v-tab>
        <v-tab href="#News"> News </v-tab>
        <v-tab href="#Stat"> Stat </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'Site'">
          <v-row no-gutters class="ma-5" align="center" justify="center">
            <v-col col="12" md="8" lg="6" xl="6">
              <site></site>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'Users'">
          <v-row no-gutters class="ma-5" align="center" justify="center">
            <v-col col="12">
              <users></users>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'Map'">
          <v-row no-gutters class="ma-5" align="center" justify="center">
            <v-col col="12">
              <v-sheet height="800">
                <Map />
              </v-sheet>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'News'">
          <v-row no-gutters class="ma-5" align="center" justify="center">
            <v-col col="12">
              <News></News>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'Stat'">
          <v-row no-gutters class="ma-5" align="center" justify="center">
            <v-col col="12" md="8" lg="6" xl="6">
              <stat></stat>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-overlay :value="accessRequestShown">
      <v-card>
        <v-form ref="form" v-model="accessKey" lazy-validation>
          <v-text-field
            class="mb-1 pa-3 pt-4 pb-0"
            label="Think Twice"
            placeholder="Type Your Access Key"
            outlined
            autofocus
            onPaste="return false"
            onCopy="return false"
            onCut="return false"
            onDrag="return false"
            onDrop="return false"
            maxlength="10"
            :rules="keyRules"
            required
            :type="showKey ? 'text' : 'password'"
            :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showKey = !showKey"
            @keyup.native="checkAccess"
          ></v-text-field>
        </v-form>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import Site from '@/components/admin/Site';
import Users from '@/components/admin/Users';
import Map from '@/components/admin/Map';
import News from '@/components/admin/News';
import Stat from '@/components/admin/Stat';

export default {
  name: 'Admin',
  components: {
    Site,
    Users,
    Map,
    News,
    Stat,
  },
  data() {
    return {
      tab: 'Site',
      accessRequestShown: true,
      accessKey: null,
      showKey: false,
      keyRules: [
        (v) => !!v || 'Access key is required!',
        (v) => v == this.userKey || 'No match so far ...',
      ],
    };
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    userAccess() {
      return this.$store.getters.getUserAccess;
    },
    userKey() {
      const key =
        this.$store.getters.getAppName +
        this.$store.getters.getAppName.length +
        new Date().getDate();
      return key.toString();
    },
    userRole() {
      return this.$store.getters.getUserRole;
    },
  },
  methods: {
    sleep(time) {
      return new Promise((resolve) => {
        setTimeout(resolve, time || 1000);
      });
    },
    checkAccess() {
      if (this.$refs.form.validate()) {
        this.accessRequestShown = false;
      }
    },
  },
  created() {
    if (!this.isAuthenticated) {
      this.$router.push('/').catch((err) => {});
    } else {
      if (this.userAccess < 5 || this.userRole.includes('Trader')) {
        this.$router.push('/').catch((err) => {});
      }
    }
  },
  mounted() {},
};
</script>

<style scoped></style>
