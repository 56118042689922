<template>
  <v-container grid-list-lg>
    <v-row align="center" class="mb-3">
      <v-expansion-panels multiple focusable v-model="expanded.panel">
        <v-expansion-panel>
          <v-expansion-panel-header class="title">{{
            strings.account.subscription
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-row
                align="center"
                class="mt-3"
                v-for="customerSubscription in customerSubscriptions"
                :key="customerSubscription.id"
              >
                <v-col cols="7">
                  <span class="text-h5">{{
                    customerSubscription.items.data[0].plan.nickname
                  }}</span>

                  <v-chip
                    v-show="customerSubscription.status.includes('incomplete')"
                    x-small
                    color="info"
                    >Check Billing for Open Invoices</v-chip
                  >
                </v-col>
                <v-col align="center" cols="3" class="text-capitalize">
                  <v-icon
                    class="pb-1"
                    v-if="customerSubscription.status.includes('active')"
                    color="green"
                    small
                    >mdi-check-circle</v-icon
                  >
                  <v-icon class="pb-1" v-else color="blue" small
                    >mdi-alert-circle</v-icon
                  >
                  {{ customerSubscription.status }}
                </v-col>
                <v-col align="right" cols="2">
                  <v-btn
                    x-small
                    color="error"
                    @click.stop="deleteButtonPressed(customerSubscription.id)"
                    >Cancel</v-btn
                  >
                </v-col>
              </v-row>
              <div class="ma-2 accent--text text-h5" v-if="showLockMessage">
                You can't resubscribe till
                {{ new Date(userLock).toLocaleString() }}
              </div>
              <v-row align="center" class="my-3">
                <v-expansion-panels multiple focusable>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="title">
                      {{ strings.account.billing }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="my-3">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Name</th>
                              <th class="text-left">Description</th>
                              <th class="text-left">Period</th>
                              <th class="text-left" min-width="60">Amount</th>
                              <th class="text-left">Status</th>
                              <th class="text-left">Invoice</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="font-weight-light"
                              v-for="stripeCustomerInvoice in stripeCustomerInvoices"
                              :key="stripeCustomerInvoice.id"
                            >
                              <td>
                                {{
                                  stripeCustomerInvoice.lines.data[0].plan
                                    .nickname
                                }}
                              </td>
                              <td class="caption" width="250px">
                                {{
                                  stripeCustomerInvoice.lines.data[0]
                                    .description
                                }}
                              </td>
                              <td>
                                {{
                                  new Date(
                                    stripeCustomerInvoice.lines.data[0].period
                                      .start * 1000
                                  ).toLocaleDateString()
                                }}
                                to
                                {{
                                  new Date(
                                    stripeCustomerInvoice.lines.data[0].period
                                      .end * 1000
                                  ).toLocaleDateString()
                                }}
                              </td>
                              <td width="90px">
                                {{
                                  stripeCustomerInvoice.amount_paid / 100 +
                                  ' ' +
                                  stripeCustomerInvoice.currency.toUpperCase()
                                }}
                              </td>
                              <td class="text-capitalize">
                                {{ stripeCustomerInvoice.status }}
                              </td>
                              <td>
                                <a
                                  :href="
                                    stripeCustomerInvoice.hosted_invoice_url
                                  "
                                  target="_blank"
                                >
                                  <v-chip
                                    v-show="
                                      stripeCustomerInvoice.status.includes(
                                        'open'
                                      ) &&
                                      customerSubscriptions[0].status.includes(
                                        'incomplete'
                                      )
                                    "
                                    x-small
                                    color="info"
                                  >
                                    Pay Link
                                  </v-chip>
                                  {{
                                    stripeCustomerInvoice.number.split('-')[1]
                                  }}
                                </a>
                                <br />
                                <a
                                  :href="stripeCustomerInvoice.invoice_pdf"
                                  target="_blank"
                                  >PDF</a
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="title">
                      {{ strings.account.cards }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="my-3">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Brand</th>
                              <th class="text-left">Name On Card</th>
                              <th class="text-left">Last 4 Digits</th>
                              <th class="text-left">Saved On</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="stripeCustomerPaymentMethod in stripeCustomerPaymentMethods"
                              :key="stripeCustomerPaymentMethod.id"
                            >
                              <td>
                                <span
                                  v-if="
                                    stripeCustomerPaymentMethod.id.includes(
                                      customerDefaultPaymentMethodId
                                    ) ||
                                    stripeCustomerPaymentMethod.id.includes(
                                      stripeCustomerObject.default_source
                                    )
                                  "
                                >
                                  <v-badge top color="green" content="active">
                                    {{
                                      stripeCustomerPaymentMethod.card.brand.toUpperCase()
                                    }}
                                  </v-badge>
                                </span>
                                <span v-else>
                                  {{
                                    stripeCustomerPaymentMethod.card.brand.toUpperCase()
                                  }}
                                </span>
                              </td>
                              <td>
                                {{
                                  stripeCustomerPaymentMethod.billing_details
                                    .name
                                    ? stripeCustomerPaymentMethod.billing_details.name.toUpperCase()
                                    : '****'
                                }}
                              </td>
                              <td>
                                ****{{ stripeCustomerPaymentMethod.card.last4 }}
                              </td>
                              <td>
                                {{
                                  new Date(
                                    stripeCustomerPaymentMethod.created * 1000
                                  ).toLocaleDateString()
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </div>
            <v-overlay :value="loading">
              <v-progress-circular
                indeterminate
                size="100"
              ></v-progress-circular>
            </v-overlay>
            <div v-if="!showLockMessage && stripeProductsObjects">
              <v-tabs
                v-model="tab"
                background-color="gray accent-1"
                centered
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#tab-1" disabled>
                  Alipay
                  <v-img
                    src="@/assets/payments/alipay.png"
                    contain
                    max-height="40"
                    max-width="60"
                  ></v-img>
                </v-tab>
                <v-tab href="#tab-2" disabled>
                  Apple Pay
                  <v-img
                    src="@/assets/payments/apple.png"
                    contain
                    max-height="40"
                    max-width="60"
                  ></v-img>
                </v-tab>
                <v-tab href="#tab-3">
                  Credit Debit
                  <v-img
                    src="@/assets/payments/credit.png"
                    contain
                    max-height="40"
                    max-width="60"
                  ></v-img>
                </v-tab>
                <v-tab href="#tab-4" disabled>
                  Google Pay
                  <v-img
                    src="@/assets/payments/google.png"
                    contain
                    max-height="40"
                    max-width="60"
                  ></v-img>
                </v-tab>
                <v-tab href="#tab-5" disabled>
                  WeChat Pay
                  <v-img
                    src="@/assets/payments/wechat_pay.png"
                    contain
                    max-height="40"
                    max-width="60"
                  ></v-img>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item :value="'tab-3'">
                  <v-row
                    no-gutters
                    class="ma-5"
                    align="center"
                    justify="center"
                  >
                    <v-col col="12" md="8" lg="6" xl="6">
                      <v-card outlined tile>
                        <v-card-text>
                          <v-sheet
                            elevation="2"
                            class="ma-4 pa-1"
                            height="75px"
                          >
                            <v-select
                              class="form-field-subscription mt-1"
                              :items="stripePricesObjects"
                              v-model="selectedProductPriceId"
                              item-text="nickname"
                              item-value="id"
                              label="Subscription"
                              background-color="white"
                              height="60"
                              outlined
                              required
                            ></v-select>
                          </v-sheet>
                          <v-sheet
                            elevation="2"
                            class="ma-4 pa-1"
                            height="90px"
                          >
                            <v-text-field
                              autofocus
                              id="name"
                              class="form-field mt-1 primary--text"
                              label="Name On Card"
                              background-color="white"
                              height="60"
                              :rules="cardOwnerRule"
                              maxlength="22"
                              counter="22"
                              outlined
                              required
                              v-model="cardOwner"
                              @input="
                                (v) => {
                                  !!cardOwner
                                    ? (cardOwner = v.toUpperCase())
                                    : cardOwner;
                                }
                              "
                              onPaste="return false"
                              onCopy="return false"
                              onCut="return false"
                              onDrag="return false"
                              onDrop="return false"
                            ></v-text-field>
                          </v-sheet>
                          <v-sheet
                            elevation="2"
                            class="ma-4 pa-1"
                            height="75px"
                          >
                            <v-text-field
                              class="form-field-email mt-1"
                              label="Account email"
                              background-color="white"
                              disabled
                              height="60"
                              v-model="email"
                              outlined
                            ></v-text-field>
                          </v-sheet>
                          <v-sheet elevation="2" class="ma-4 pa-1">
                            <div id="card-number-element"></div>
                          </v-sheet>
                          <v-sheet elevation="2" class="ma-4 pa-1">
                            <div id="card-expiry-element"></div>
                          </v-sheet>
                          <v-sheet elevation="2" class="ma-4 pa-1">
                            <div id="card-cvc-element"></div>
                          </v-sheet>
                          <v-sheet
                            class="ma-4 pa-1"
                            align="center"
                            justify="center"
                          >
                            <v-btn
                              depressed
                              large
                              color="accent"
                              :disabled="!stripeFormIsReady"
                              @click.stop="subscribeButtonPressed"
                            >
                              Subsribe for $
                              <span v-if="selectedProductPriceObject">
                                {{
                                  selectedProductPriceObject[0].unit_amount /
                                  100
                                }}
                                per
                                {{
                                  selectedProductPriceObject[0].recurring
                                    .interval
                                }}
                              </span>
                              <span v-else>10 per month</span>
                            </v-btn>
                            <div style="color: red">
                              {{ stripeValidationError }}
                            </div>
                          </v-sheet>
                          <a
                            href="https://stripe.com/customers"
                            target="_blank"
                          >
                            <v-img
                              src="@/assets/payments/secure-stripe-payment-logo.png"
                              contain
                              max-height="80"
                            ></v-img>
                          </a>
                        </v-card-text>
                        <v-card-actions></v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :disabled="siteSettings && !siteSettings.feedback">
          <v-expansion-panel-header class="title"
            >Feedback</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-form
              ref="feedbackForm"
              v-model="feedbackFormValid"
              lazy-validation
            >
              <v-text-field
                class="mt-3 pb-1"
                ref="feedback"
                placeholder="Your feedback is much appreciated!"
                outlined
                clearable
                name="feedback"
                v-model="feedback"
                :rules="feedbackRules"
                maxlength="120"
                counter="120"
                onPaste="return false"
                onCopy="return false"
                onCut="return false"
                onDrag="return false"
                onDrop="return false"
                required
                :disabled="feedbackSent"
              ></v-text-field>
              <v-btn
                @click.stop="submitFeedback"
                color="accent"
                depressed
                block
                :disabled="
                  (siteSettings && !siteSettings.feedback) ||
                  !feedbackFormValid ||
                  !feedback
                "
                >Send</v-btn
              >
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :disabled="!userReady" class="mb-5">
          <v-expansion-panel-header class="title">API</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="my-3">
              {{ strings.account.key }}: {{ apiKey.slice(0, 30) }}
            </div>
            <div>
              Every call should contain these headers: <br />
              Content-Type - application/json<br />
              Authorization - Bearer {{ apiKey.slice(0, 30) }}<br />
              Authentication - {{ email }}<br />
              <div>
                Current subscription endpoints:
                <span
                  v-if="
                    userDatabaseRecord && userDatabaseRecord.userPackage > 1
                  "
                >
                  <code>Tickers, News, Ticker Record</code>
                </span>
                <span v-else>
                  <code>Ticker Record</code> Upgrade for full access!
                </span>
              </div>
              <hr />
              Tickers:<br />
              Url: <code>https://softalp.com/api/v1/tickers</code><br />
              Parameters: <code>market</code> Options:
              <code>america,england,europe,india,china,japan</code><br />
              Example:
              <code>https://softalp.com/api/v1/tickers?market=america</code
              ><br />
              Returns: today's and week back tickers you see on dashboard, gauge
              value: <br />
              0-45: green portion means 'POSITIVE' positive sentiment, lesser
              value lean towards stronger sentiment <br />
              45-55: yellow portion means 'NEUTRAL' neutral sentiment <br />
              55-100: red portion means 'NEGATIVE' negative sentiment, greater
              value lean towards stronger sentiment
              <hr />
              News:<br />
              Url: <code>https://softalp.com/api/v1/news</code><br />
              Parameters: <code>market</code> Options:
              <code>america,england,europe,india,china,japan</code><br />
              Example:
              <code>https://softalp.com/api/v1/news?market=america</code><br />
              Returns: today's and week back ticker's news with links
              <hr />
              Ticker record:<br />
              Url: <code>https://softalp.com/api/v1/tickers/records</code><br />
              Parameters: <code>tickers</code> Options: <code>AAPL,TSLA</code
              ><br />
              Example:
              <code
                >https://softalp.com/api/v1/tickers/records?tickers=AAPL,TSLA</code
              ><br />
              Returns: ticker information
              <hr />
              Assets:<br />
              Url: <code>https://softalp.com/api/v1/assets/{assetType}</code
              ><br />
              Parameters: <code>assetType</code> Options:
              <code>crypto,currency,metals,funds</code><br />
              Example:
              <code>https://softalp.com/api/v1/assets/crypto</code><br />
              Returns: all available asset names by type
              <hr />
              Assets data:<br />
              Url:
              <code>https://softalp.com/api/v1/assets/data/{assetType}</code
              ><br />
              Parameters:
              <code>assetType</code> Options:
              <code>crypto,currency,metals,funds</code>
              <br />
              <code>name</code> Options: <code>any name from assets call</code
              ><br />
              <code>data</code> Options: <code>mood, news, price, stat</code
              ><br />
              Example:
              <code
                >https://softalp.com/api/v1/assets/data/metals?name=SILVER&data=stat</code
              ><br />
              Returns: asset information by name and data type
              <hr />
              Crypto data:<br />
              Url:
              <code>https://softalp.com/api/v1/crypto/{dataType}</code><br />
              Parameters:
              <code>dataType</code> Options:
              <code>gappers,lists</code>
              <br />
              Example 1:
              <code>https://softalp.com/api/v1/crypto/gappers</code><br />
              Returns: common crypto names trending accross platforms
              <br />
              Example 2:
              <code>https://softalp.com/api/v1/crypto/lists</code><br />
              Returns: current crypto names list with ~400 names
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Info',
  data() {
    return {
      tab: 'tab-3',
      stripe: null,
      style: null,
      cardOwner: '',
      cardOwnerRule: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length > 3) || 'Name must be more than 3 characters',
        (v) => /^[a-zA-Z\s]*$/.test(v) || 'Name must contain letters only',
      ],
      feedback: null,
      feedbackSent: false,
      feedbackFormValid: false,
      feedbackRules: [(v) => !!v || 'Field is required'],
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCvcElement: null,
      selectedProductPriceId: 'price_1IYIVXK1MwaWazGzekWjIFUt',
      selectedProductPriceObject: null,
      stripeProductsPlans: null,
      stripeLastInvoiceSucceeded: false,
      stripeValidationError: '',
      stripeFormFieldsFilled: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
      },
      amount: 3000,
      customerDefaultPaymentMethodId: null,
      customerSubscriptions: null,
      loading: true,
    };
  },
  mounted() {
    if (!this.isAuthenticated || !this.isVerified) {
      this.$router.push('/').catch((err) => {});
    }
    this.stripe = Stripe(this.stripeKey, { apiVersion: '2020-03-02' });
    this.setStripeElementsStyle();

    if (this.isAuthenticated && this.isVerified && this.email) {
      this.getCustomer();
      this.getAssets();
    }
  },
  methods: {
    submitFeedback() {
      if (this.$refs.feedbackForm.validate()) {
        this.$store.dispatch('sendUserFeedback', this.feedback);
        this.$refs.feedback.clearableCallback();
        this.feedback = null;
        this.feedbackSent = true;
        this.feedbackFormValid = true;
      }
    },
    createAndMountFormElements() {
      var elements = this.stripe.elements();

      this.cardNumberElement = elements.create('cardNumber', {
        style: this.style,
      });
      this.cardNumberElement.mount('#card-number-element');

      this.cardExpiryElement = elements.create('cardExpiry', {
        style: this.style,
      });
      this.cardExpiryElement.mount('#card-expiry-element');

      this.cardCvcElement = elements.create('cardCvc', { style: this.style });
      this.cardCvcElement.mount('#card-cvc-element');

      this.cardNumberElement.on('change', this.setValidationError);
      this.cardExpiryElement.on('change', this.setValidationError);
      this.cardCvcElement.on('change', this.setValidationError);
    },

    setValidationError(event) {
      if (event.complete) {
        this.stripeFormFieldsFilled[event.elementType] = true;
        this.stripeValidationError = '';
      } else if (event.error) {
        this.stripeFormFieldsFilled[event.elementType] = false;
        this.stripeValidationError = event.error.message;
      } else {
        this.stripeFormFieldsFilled[event.elementType] = false;
      }
    },

    subscribeButtonPressed() {
      let cardName = document.getElementById('name').value;
      this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.cardNumberElement,
          billing_details: {
            email: this.email,
            name: cardName,
          },
        })
        .then((result) => {
          document.getElementById('name').value = '';
          this.cardNumberElement.clear();
          this.cardExpiryElement.clear();
          this.cardCvcElement.clear();
          if (result.error) {
            console.error(`\nError had occur: ${result.error.message}\n`);
          } else {
            const subscriptionOptions = {
              customerId: this.stripeCustomerId,
              paymentMethodId: result.paymentMethod.id,
              priceId: this.selectedProductPriceId,
              current: this.customerSubscriptions,
            };
            this.createSubscription({
              stripe: this.stripe,
              payload: subscriptionOptions,
            });
          }
        });
    },

    deleteButtonPressed(id) {
      const message = this.userLock
        ? `Are you sure about canceling current subscription? 
                  You will not be able to resubscribe till ${new Date(
                    this.userLock
                  ).toLocaleString()}`
        : `Are you sure about canceling current subscription?`;
      const settings = {
        message: message,
        action: this.deleteSubscription,
        actionParameters: {
          id: id,
        },
      };
      this.$store.dispatch('showConfirmDialogAction', settings);
    },

    setStripeElementsStyle() {
      this.style = {
        base: {
          iconColor: '#c4f0ff',
          color: '#87BBFD',
          fontWeight: 200,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '30px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#BDBDBD',
          },
        },
        invalid: {
          iconColor: '#D50000',
          color: '#D50000',
        },
      };
    },
    getCustomer() {
      this.$store.dispatch('getCustomerAction');
    },
    getAssets() {
      this.$store.dispatch('getAssetsAction');
    },
    createSubscription(subscriptionOptions) {
      this.$store.dispatch('createSubscriptionAction', subscriptionOptions);
    },
    updateSubscription(subscriptionOptions) {
      this.$store.dispatch('updateSubscriptionAction', subscriptionOptions);
    },
    deleteSubscription(subscriptionOptions) {
      this.$store.dispatch('deleteSubscriptionAction', subscriptionOptions);
    },
    setCustomerData() {
      this.$store.dispatch('getInvoicesAction');
      this.$store.dispatch('getPaymentMethodsAction');
      if (this.stripeCustomerObject.invoice_settings.default_payment_method) {
        this.customerDefaultPaymentMethodId = this.stripeCustomerObject.invoice_settings.default_payment_method;
      }
      if (this.stripeCustomerObject.subscriptions.total_count > 0) {
        this.customerSubscriptions = this.stripeCustomerObject.subscriptions.data;
      } else {
        this.customerSubscriptions = null;
      }
    },
  },
  watch: {
    userDataChanged() {},
    stripeSubscriptionId() {},
    stripeSubscriptionObject() {
      if (this.stripeSubscriptionObject.status.includes('canceled')) {
        // Get updated customer record from stripe
        this.$store.dispatch('getCustomerAction');
        // Show snackbar
        this.$store.dispatch('showSnackbarAction', {
          status: true,
          settings: {
            timeout: 10000,
            color: 'info',
            text: `Subscription cancelled!`,
          },
        });
      }
      if (
        this.stripeSubscriptionObject.latest_invoice.payment_intent &&
        this.stripeSubscriptionObject.latest_invoice.payment_intent.status.includes(
          'succeeded'
        )
      ) {
        this.stripeLastInvoiceSucceeded = true;
        const userPackageName = this.stripeSubscriptionObject.latest_invoice
          .lines.data[0].plan.nickname;
        let userPackage;
        switch (true) {
          case userPackageName.includes('Investor'):
            userPackage = 1;
            break;
          case userPackageName.includes('Developer'):
            userPackage = 2;
            break;
          case userPackageName.includes('White'):
            userPackage = 3;
            break;
          default:
            userPackage = 1;
            break;
        }
        console.log(
          'User package will be updated: \n\n' +
            JSON.stringify(userPackage, null, 2)
        );
        this.$store.dispatch('userClaimsUpdate', {
          userPaid: true,
          userPackage: userPackage,
        });
        // Get updated customer record from stripe
        this.$store.dispatch('getCustomerAction');
        // Show snackbar
        this.$store.dispatch('showSnackbarAction', {
          status: true,
          settings: {
            timeout: 10000,
            color: 'success',
            text: `Payment processed successfully!`,
          },
        });
      }
    },
    stripeSubscriptionError() {
      // Getting possible incomplete subscription data
      this.$store.dispatch('getCustomerAction');
    },
    stripeCustomerId() {},
    // Watch stripeCustomerObject
    stripeCustomerObject() {
      // Subscription is just changed need to update user data
      if (this.stripeCustomerObject.subscriptions.total_count > 0) {
        // Update user if invoice paid out of service by link
        if (
          this.stripeCustomerObject.subscriptions.data[0].status.includes(
            'active'
          ) &&
          (!this.isPaid || !this.userReady)
        ) {
          const userPackageName = this.stripeCustomerObject.subscriptions
            .data[0].items.data[0].plan.nickname;
          let userPackage;
          switch (true) {
            case userPackageName.includes('Day'):
              userPackage = 1;
              break;
            case userPackageName.includes('Algo'):
              userPackage = 2;
              break;
            case userPackageName.includes('White'):
              userPackage = 3;
              break;
            default:
              userPackage = 1;
              break;
          }
          console.log(
            'User package will be updated: \n\n' +
              JSON.stringify(userPackage, null, 2)
          );
          let userUpdatePayload = {
            userPaid: true,
            userPackage: userPackage,
          };
          this.$store.dispatch('userClaimsUpdate', userUpdatePayload);
          this.$store.dispatch('userDatabaseUpdate', {
            ...userUpdatePayload,
            ...{ userMeta: { stripeCustomer: this.stripeCustomerObject } },
          });
        }
        // Update user if last invoice paid failed during current subscription
        if (
          this.stripeCustomerObject.subscriptions.data[0].status.includes(
            'inactive'
          ) &&
          (this.isPaid || this.userReady)
        ) {
          let userUpdatePayload = {
            userPaid: false,
            userPackage: 0,
          };
          this.$store.dispatch('userClaimsUpdate', userUpdatePayload);
          this.$store.dispatch('userDatabaseUpdate', {
            ...userUpdatePayload,
            ...{ userMeta: { stripeCustomer: this.stripeCustomerObject } },
          });
        }
      }

      // User just update their subscription, cancel etc.
      if (this.stripeSubscriptionObject) {
        let userDatabaseUpdatePayload;
        if (this.stripeCustomerObject.subscriptions.total_count == 0) {
          userDatabaseUpdatePayload = {
            userPaid: false,
            userPackage: 0,
          };
          this.$store.dispatch('userClaimsUpdate', userDatabaseUpdatePayload);
        }
        this.$store.dispatch('userDatabaseUpdate', {
          ...userDatabaseUpdatePayload,
          ...{ userMeta: { stripeCustomer: this.stripeCustomerObject } },
        });
      }
      this.setCustomerData();
    },
    stripeProductsObjects() {},
    // Watch user choosing price
    selectedProductPriceId() {
      this.selectedProductPriceObject = this.stripePricesObjects.filter(
        (object) => {
          return object.id == this.selectedProductPriceId;
        }
      );
    },
    // Watch objects received from backend initially
    stripePricesObjects() {
      if (!this.showLockMessage && this.stripePricesObjects != null) {
        this.createAndMountFormElements();
      } else {
        this.loading = false;
      }
      // Set initial default value
      this.selectedProductPriceId = this.siteEnvironment.includes('production')
        ? 'price_1IYIZHK1MwaWazGzdNEThLH8'
        : 'price_1IYIVXK1MwaWazGzekWjIFUt';
      this.selectedProductPriceObject = this.stripePricesObjects.filter(
        (object) => {
          return object.id == this.selectedProductPriceId;
        }
      );
      this.loading = false;
    },
  },
  computed: {
    showLockMessage() {
      return (
        this.userLock &&
        this.stripeCustomerObject &&
        !this.isPaid &&
        new Date(this.userLock) > new Date()
      );
    },
    userLock() {
      return this.userDatabaseRecord
        ? this.userDatabaseRecord.userMeta.userLock
        : null;
    },
    siteSettings() {
      return this.$store.getters.getSiteSettings;
    },
    siteEnvironment() {
      return this.$store.getters.getEnvironment;
    },
    stripeKey() {
      let stripeKey;
      if (this.siteEnvironment.includes('production')) {
        console.log(`\nP\n`);
        stripeKey = process.env.VUE_APP_STRIPE_PK_LIVE;
      } else {
        console.log(`\nS\n`);
        stripeKey = process.env.VUE_APP_STRIPE_PK_TEST;
      }
      return stripeKey;
    },
    stripeFormIsReady() {
      return (
        this.cardOwner.length > 3 &&
        this.stripeFormFieldsFilled.cardNumber &&
        this.stripeFormFieldsFilled.cardExpiry &&
        this.stripeFormFieldsFilled.cardCvc
      );
    },
    stripeSubscriptionId() {
      return this.$store.getters.getSubscriptionId;
    },
    stripeSubscriptionObject() {
      return this.$store.getters.getSubscriptionObject;
    },
    stripeSubscriptionError() {
      return this.$store.getters.getSubscriptionError;
    },
    stripeCustomerId() {
      return this.$store.getters.getCustomerId;
    },
    stripeCustomerObject() {
      return this.$store.getters.getCustomerObject;
    },
    stripeCustomerInvoices() {
      return this.$store.getters.getCustomerInvoices;
    },
    stripeCustomerPaymentMethods() {
      return this.$store.getters.getCustomerPaymentMethods;
    },
    stripeProductsObjects() {
      return this.$store.getters.getProductsObjects;
    },
    stripePricesObjects() {
      return this.$store.getters.getPricesObjects
        ? this.$store.getters.getPricesObjects.filter((object) => {
            return (
              object.nickname.includes('Investor') ||
              object.nickname.includes('Developer')
            );
          })
        : [];
    },
    expanded() {
      return { panel: [0] };
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isVerified() {
      return this.$store.getters.isUserVerified;
    },
    userDatabaseRecord() {
      return this.$store.getters.getUserDatabaseRecord;
    },
    userReady() {
      return this.$store.getters.isUserReady;
    },
    userDataChanged() {
      return this.$store.getters.isUserDataChanged;
    },
    isPaid() {
      return this.$store.getters.isUserPaid;
    },
    name() {
      return this.$store.getters.getUserName;
    },
    email() {
      return this.$store.getters.getUserEmail;
    },
    apiKey() {
      return this.$store.getters.getUserApiKey;
    },
    apiCalls() {
      return this.$store.getters.getUserApiCalls;
    },
    role() {
      return this.$store.getters.getUserRole;
    },
    settings() {
      return this.$store.getters.getUserSettings;
    },
    strings() {
      return this.$store.getters.getStrings;
    },
  },
};
</script>

<style scoped src="@/assets/styles/payments.css"></style>
