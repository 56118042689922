var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmaps-map',{attrs:{"options":_vm.mapOptions}},_vm._l((_vm.usersMarkers),function(marker,i){return _c('gmaps-marker',{directives:[{name:"show",rawName:"v-show",value:(marker.lastLoginLocation),expression:"marker.lastLoginLocation"}],key:i,attrs:{"position":marker.lastLoginLocation,"icon":"http://maps.google.com/mapfiles/ms/micons/dollar.png","title":'Username: ' +
      marker.userName +
      '\n' +
      'Paid: ' +
      marker.userPaid +
      '\n' +
      'Created: ' +
      new Date(marker.userCreated).toLocaleString() +
      '\n' +
      'Last login: ' +
      new Date(marker.lastLogin).toLocaleString() +
      '\n' +
      'Last login location: ' +
      marker.lastLoginLocation
        ? marker.lastLoginLocation.lat.toString()
        : 'unknown' + ',' + marker.lastLoginLocation
        ? marker.lastLoginLocation.lng.toString()
        : 'unknown' + '\n' + 'Total locations: ' + marker.totalLoginLocations}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }