var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.users)?_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"userEmail","show-expand":"","search":_vm.search,"calculate-widths":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Users")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.userMeta",fn:function(ref){
var item = ref.item;
return [(item.userMeta.userCreated)?_c('span',[_vm._v(" "+_vm._s(new Date(item.userMeta.userCreated) instanceof Date && !isNaN(new Date(item.userMeta.userCreated)) ? new Date(item.userMeta.userCreated).toLocaleDateString() : '---')+" ")]):_vm._e()]}},{key:"item.userPaid",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.userPaid))])]}},{key:"item.userMarket",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.userMarket))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-sheet'),_c('v-sheet',[_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","tile":""}},_vm._l((Object.keys(item)),function(key,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(key)+" : "),(JSON.stringify(item[key]).length < 30)?_c('span',[_vm._v(" : "+_vm._s(item[key]))]):_c('span',[_vm._v(" : Expand for more info ...")])]),_c('v-expansion-panel-content',[(typeof item[key] === 'object')?_c('span',[_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","tile":""}},_vm._l((Object.keys(item[key])),function(key2,j){return _c('v-expansion-panel',{key:j},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(key2)+" : "),(JSON.stringify(item[key][key2]).length < 30)?_c('span',[_vm._v(" : "+_vm._s(item[key][key2]))]):_c('span',[_vm._v(" : Expand for more info ...")])]),_c('v-expansion-panel-content',[_c('code',[_c('pre',[_vm._v(_vm._s(item[key][key2]))])])])],1)}),1)],1):_c('span',[_c('code',[_c('pre',[_vm._v(_vm._s(item[key]))])])])])],1)}),1)],1),_c('v-sheet',{attrs:{"height":"300"}},[_c('gmaps-map',{attrs:{"options":_vm.mapOptions}},_vm._l((_vm.userMarkers),function(marker,i){return _c('gmaps-marker',{key:i,attrs:{"position":marker.lastLoginLocation,"icon":"http://maps.google.com/mapfiles/ms/micons/dollar.png","title":'Username: ' +
                item.userName +
                '\n' +
                'Paid: ' +
                marker.userPaid +
                '\n' +
                'Created: ' +
                new Date(marker.userCreated).toLocaleString() +
                '\n' +
                'Last login: ' +
                new Date(marker.lastLogin).toLocaleString() +
                '\n' +
                'Last login location: ' +
                marker.lastLoginLocation.lat.toString() +
                ',' +
                marker.lastLoginLocation.lng.toString() +
                '\n' +
                'Total locations: ' +
                marker.totalLoginLocations}})}),1)],1)],1)]}}],null,false,2611834178)}),_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.feedbacks),function(item,idx){return _c('v-flex',{key:idx,attrs:{"xs4":""}},[_c('v-card',{staticClass:"ma-3"},[_c('v-card-title',[_vm._v(" "+_vm._s(new Date(item.created).getUTCFullYear())+"-"+_vm._s(new Date(item.created).getUTCMonth())+"-"+_vm._s(new Date(item.created).getUTCDate())+" @ "+_vm._s(new Date(item.created).getUTCHours())+":"+_vm._s(new Date(item.created).getUTCMinutes())+" ")]),_c('v-card-text',[_c('br'),_vm._v(" "+_vm._s(item.from)+" "),_c('br'),_c('hr'),_vm._v(" "+_vm._s(item.to)+" "),_c('br'),_c('hr'),_vm._v(" "+_vm._s(item.feedback)+" ")])],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }