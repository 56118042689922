<template>
  <gmaps-map :options="mapOptions">
    <!-- http://www.lass.it/Web/viewer.aspx?id=4 -->
    <gmaps-marker
      v-for="(marker, i) in usersMarkers"
      v-show="marker.lastLoginLocation"
      :key="i"
      :position="marker.lastLoginLocation"
      icon="http://maps.google.com/mapfiles/ms/micons/dollar.png"
      :title="
        'Username: ' +
        marker.userName +
        '\n' +
        'Paid: ' +
        marker.userPaid +
        '\n' +
        'Created: ' +
        new Date(marker.userCreated).toLocaleString() +
        '\n' +
        'Last login: ' +
        new Date(marker.lastLogin).toLocaleString() +
        '\n' +
        'Last login location: ' +
        marker.lastLoginLocation
          ? marker.lastLoginLocation.lat.toString()
          : 'unknown' + ',' + marker.lastLoginLocation
          ? marker.lastLoginLocation.lng.toString()
          : 'unknown' + '\n' + 'Total locations: ' + marker.totalLoginLocations
      "
    />
  </gmaps-map>
</template>

<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps';

export default {
  name: 'Map',
  components: {
    gmapsMap,
    gmapsMarker,
  },
  data() {
    // NYSE 40.706881,-74.0134541
    return {
      usersMarkers: null,
      mapOptions: {
        center: { lat: 40.7068, lng: -74.0134 },
        zoom: 8,
        backgroundColor: '#EEEEEE',
      },
    };
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.$store.getters.getUserRole.includes('Admin');
    },
  },
  methods: {
    fetchUsers() {
      this.$store.dispatch('getUsersRecords');
    },
    setUsersMarkers() {
      let usersWithGeo = this.users.filter((object) => {
        return object.userMeta.userGeoLocation;
      });
      // Filter for existing locations
      this.usersMarkers = usersWithGeo.map((object) => {
        const userLocationsIPs = Object.keys(object.userMeta.userGeoLocation);
        const userLastLocation =
          object.userMeta.userGeoLocation[
            userLocationsIPs[userLocationsIPs.length - 1]
          ];
        return {
          userName: object.userName,
          userPaid: object.userPaid,
          userCreated: object.userMeta.userCreated,
          lastLogin: object.userMeta.userLastLogin,
          lastLoginLocation: {
            lat: parseFloat(userLastLocation.loc.split(',')[0]),
            lng: parseFloat(userLastLocation.loc.split(',')[1]),
          },
          totalLoginLocations: userLocationsIPs.length,
        };
      });
      // Set map to latest user location
      this.mapOptions.center = this.usersMarkers[
        this.usersMarkers.length - 1
      ].lastLoginLocation;
      this.mapOptions.zoom = 12;
    },
  },
  mounted() {
    if (!this.users) {
      this.fetchUsers();
    } else {
      this.setUsersMarkers();
    }
  },
  created() {},
  watch: {
    users() {
      this.setUsersMarkers();
    },
  },
};
</script>

<style scoped></style>
